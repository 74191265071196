import React from 'react'
import { KitchenArray } from '../PortfolioModal/kitchensArray'
import PortfolioModal from '../PortfolioModal/PortfolioModal'
import { LaundryArray } from '../PortfolioModal/LaundryArray'
import { StorageArray } from '../PortfolioModal/StorageArray'
import { MDBCol, MDBRow, MDBAnimation } from 'mdbreact'

import '../Home/style.css'
import ScrollToTop from '../Nav/ScrollToTop'

import { Helmet } from 'react-helmet'
import { ProjectArray } from '../PortfolioModal/ProjectArray'

class PortfolioPage extends React.Component {
  render() {
    const overlay = <div id='sidenav-overlay' style={{ backgroundColor: 'transparent' }} onClick={this.handleTogglerClick} />
    return (
      <>
        <Helmet>
          <title>Our Portfolio | Current Kitchens</title>
          <meta
            name='description'
            content='We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch.'
          />
          <link rel='canonical' href='https://www.currentkitchens.co.nz/portfolio' />
        </Helmet>
        <ScrollToTop />
        <div className='pt-6' id='portfolio'>
          <div>
            <div className='portfolio-page-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter'>
              <MDBCol md='6' className='header-text mx-auto text-left header-text-center'>
                <div className='pt-5'>
                  <MDBAnimation reveal type='fadeInDown' className='fast'>
                    <h1 className='h4-responsive beige-text text-uppercase mt-5 mb-2'>Our portfolio</h1>
                  </MDBAnimation>
                  <MDBAnimation reveal type='fadeInUp' className='fast'>
                    <h1 className='h1-responsive font-weight-md'>Explore a selection of our kitchens, laundries, vanities and storage.</h1>
                  </MDBAnimation>
                </div>
              </MDBCol>
            </div>
            <MDBRow>
              <MDBCol md='6' className='p-0 m-0'>
                <div class='view hover zoom'>
                  <img
                    loading='lazy'
                    src={require('../../assets/img/kitchens.png')}
                    className='img-fluid w-100 grid-image'
                    alt='Kitchens'
                  />
                  <div className='flex-center rgba-white-strong mask'>
                    <div className='mx-auto text-center'>
                      <PortfolioModal
                        title={'Kitchens'}
                        subtitle={'kitchen'}
                        description={
                          'Discover the beautiful range of Kitchens we can design and install, meeting any budget and requirements. Our elegant kitchens combine high quality hardware and beautiful kitchen cabinetry with exceptional service and installation.'
                        }
                        // images={ProjectArray}
                        // imgCount={'48'}
                      />
                    </div>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md='6' className='p-0 m-0'>
                <div class='view hover zoom'>
                  <img
                    loading='lazy'
                    src={require('../../assets/img/laundries.png')}
                    className='img-fluid w-100 grid-image'
                    alt='laundry'
                  />
                  <div className='flex-center rgba-white-strong mask'>
                    <div className='mx-auto text-center'>
                      {/*Task 3.2 - Start of Laundry modal should create different pages for the images provided here refer to the Porfolio Modal component*/}
                      <PortfolioModal
                        title={'Laundries'}
                        subtitle={'laundries'}
                        description={'We can design and install custom laundries to fit your space, style and budget.'}
                        // images={ProjectArray}
                        // imgCount={'6'}
                      />
                    </div>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className='p-0 m-0'>
                <div class='view hover zoom'>
                  <img loading='lazy' src={require('../../assets/img/storage.png')} className='img-fluid w-100 grid-image' alt='storage' />
                  <div className='flex-center rgba-white-strong mask'>
                    <div className='mx-auto text-center'>
                      <PortfolioModal
                        title={'Storage'}
                        subtitle={'storage'}
                        description={
                          'Make the most of any space with our unique range of high quality storage drawers, cupboards and accessories. We can design and source a unique range of storage cabinets, drawers and cupboards to make the most out of your spaces. All our accessories are top quality.'
                        }
                        // images={ProjectArray}
                        // imgCount={'21'}
                      />
                    </div>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md='6' className='p-0 m-0'>
                <div class='view hover zoom'>
                  <img loading='lazy' src={require('../../assets/img/vanities.JPG')} className='img-fluid w-100 grid-image' alt='storage' />
                  <div className='flex-center rgba-white-strong mask'>
                    <div className='mx-auto text-center'>
                      <PortfolioModal
                        title={'Vanities'}
                        subtitle={'vanities'}
                        description={
                          'Make the most of any space with our unique range of high quality storage drawers, cupboards and accessories. We can design and source a unique range of storage cabinets, drawers and cupboards to make the most out of your spaces. All our accessories are top quality.'
                        }
                        // images={ProjectArray}
                        // imgCount={'21'}
                      />
                    </div>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </>
    )
  }
}

export default PortfolioPage
