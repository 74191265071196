import React from 'react'
import { withRouter } from 'react-router-dom'
import { ProjectArray } from '../PortfolioModal/ProjectArray'
// import { KitchenArray } from '../PortfolioModal/kitchensArray';
import { MDBCol, MDBAnimation, MDBBtn, MDBRow, MDBContainer } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import CarouselComponent from '../Portfolio/CarouselComponent'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'
import KitchenMultiItem from './KitchenMultiItem'

class KitchenItem extends React.Component {
  render() {
    const query = new URLSearchParams(this.props.location.search)
    const id = query.get('id')
    const selectedKitchenProject = ProjectArray.find((project) => project.id == id)
    const kitchenProjectName = (
      <>
        <h1>{selectedKitchenProject.name}</h1>
      </>
    )

    if (selectedKitchenProject.multipleProjects && selectedKitchenProject.multipleProjects.length > 0) {
      return <KitchenMultiItem project={selectedKitchenProject} />
    }
    // const kitchenProjectDescription = (
    //     <>
    //         <p>{selectedKitchenProject.description}</p>
    //     </>
    // )
    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{selectedKitchenProject.name + ' | Current Kitchens'}</title>
          <meta
            name='description'
            content='We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch.'
          />
          <link rel='canonical' href={'https://www.currentkitchens.co.nz/kitchen-item' + this.props.location.search} />
          {/* <meta property="og:image" content={"https://www.currentkitchens.co.nz" + selectedKitchenProject.coverImgUrl} /> */}
        </Helmet>
        <ScrollToTop />
        <div className='kitchen-page-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter'>
          <MDBCol md='6' className='header-text mx-auto text-left header-text-center'>
            <div className='pt-5'>
              <MDBAnimation reveal type='fadeInDown' className='fast'>
                <h1 className='h4-responsive beige-text text-uppercase mt-5 mb-2'>Our Portfolio</h1>
              </MDBAnimation>
              <MDBAnimation reveal type='fadeInUp' className='fast'>
                <h1 className='h1-responsive font-weight-md'>{kitchenProjectName}</h1>
              </MDBAnimation>
            </div>
          </MDBCol>
        </div>
        <MDBContainer>
          <MDBRow className='pt-5'>
            <MDBCol md='1'>
              <i class='fas fa-chevron-left fa-lg  mt-3 mb-4 pointer' onClick={() => this.props.history.push('/our-kitchens')}></i>
            </MDBCol>
            <MDBCol md='11'>
              <div className='h6-responsive  mb-5 text-left mt-2'>{ReactHtmlParser(selectedKitchenProject.description)}</div>
            </MDBCol>
          </MDBRow>

          <div
            style={{
              paddingBottom: '5rem'
            }}
          >
            <CarouselComponent projectTypeArray={ProjectArray} selectedTypeProject={selectedKitchenProject} />

            <div className='text-left mt-lg-5 mt-5'>
              <MDBBtn color='secondary' onClick={() => this.props.history.push(`/our-kitchens`)}>
                Back
              </MDBBtn>
            </div>
          </div>
        </MDBContainer>
      </>
    )
  }
}

export default withRouter(KitchenItem)
