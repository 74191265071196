import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import './style.css';


class FooterPage extends React.Component {

  authCtx = null;

  navigate = (url) => {
    // navigate to top of page
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  toggleAuthModal = (show, page) => {
    this.authCtx.toggleShow(show, page);
  }

  logout = () => {
    this.authCtx.logout();
  }

  render() {
    this.authCtx = this.context.auth;

    return (
      <MDBFooter color="default-color" className="font-small">
        <div style={{ backgroundColor: "#c6ad95" }}>
          <MDBContainer className="text-center text-md-left pt-2 pb-2">
            <MDBRow className="py-4 d-flex align-items-center"></MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer className="mt-5 mb-4 text-center text-md-left footer-padding-top-bottom">
          <MDBRow className="mt-3">
            <MDBCol md="6" className="mb-4 m-lr-auto">
              <h6 className="text-uppercase font-rubikRegular beige-text">
                <strong>Contact</strong>
              </h6>
              <hr className="white accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
              <p>
                <i className="fa fa-phone mr-3" /> <a className="white-text" href="tel:+6421355215">+64 21 355 215</a>
              </p>
              <p>
                <i className="fa fa-envelope mr-3" /> <a className="white-text" href="mailto:info@currentkitchens.co.nz">info@currentkitchens.co.nz</a>
              </p>
              <p>
                <i className="fa fa-map-marker-alt mr-3" /> Christchurch, New Zealand
            </p>
            </MDBCol>
            <MDBCol md="6" className="mb-4 m-lr-auto">
              <h6 className="text-uppercase font-rubikRegular beige-text">
                <strong>Current Kitchens</strong>
              </h6>
              <hr className="white accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
              <p>We specialise in the design and installation of bespoke commercial and residential kitchens in New Zealand. Our functional, contemporary kitchen designs are created with top of the line hardware at cost effective prices. <br /><br />
              Current Kitchens was formerly Couture Kitchens.<br /><br />
              All Rights Reserved.
            </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright footer-padding font-text text-center">
          <MDBContainer fluid>
            <br />
            <div className="white-text text-uppercase pt-5">
              &copy; {new Date().getFullYear()} Copyright Current Kitchens
            </div>
            <p className="text-uppercase mb-2 grey-text"><a href="http://xodesoftware.com/" rel="noopener noreferrer" alt="Designed by xodesoftware.com" target="_blank">Designed by Xode</a></p>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}
export default FooterPage;