import React from 'react'
import { withRouter } from 'react-router-dom'
import { ProjectArray } from '../PortfolioModal/ProjectArray'
// import { LaundryArray } from '../PortfolioModal/LaundryArray';
import { MDBBtn, MDBAnimation, MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import CarouselComponent from '../Portfolio/CarouselComponent'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'

class LaundryItem extends React.Component {
  render() {
    const query = new URLSearchParams(this.props.location.search)
    const id = query.get('id')
    const selectedLaundryProject = ProjectArray.find((project) => project.id == id)

    const laundryProjectName = (
      <>
        <h1>{selectedLaundryProject.name}</h1>
      </>
    )
    // const laundryProjectDescription = (
    //     <>

    //         <p>{selectedLaundryProject.description}</p>
    //     </>
    // )
    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{selectedLaundryProject.name + ' | Current Kitchens'}</title>
          <meta
            name='description'
            content='We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch.'
          />
          <link rel='canonical' href={'https://www.currentkitchens.co.nz/laundry-item' + this.props.location.search} />
          <meta property='og:image' content={'https://www.currentkitchens.co.nz' + selectedLaundryProject.coverImgUrl} />
        </Helmet>
        <ScrollToTop />
        <div className='laundries-page-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter'>
          <MDBCol md='6' className='header-text mx-auto text-left header-text-center'>
            <div className='pt-5'>
              <MDBAnimation reveal type='fadeInDown' className='fast'>
                <h1 className='h4-responsive beige-text text-uppercase mt-5 mb-2'>Our Portfolio</h1>
              </MDBAnimation>
              <MDBAnimation reveal type='fadeInUp' className='fast'>
                <h1 className='h1-responsive font-weight-md'>{laundryProjectName}</h1>
              </MDBAnimation>
            </div>
          </MDBCol>
        </div>
        <MDBContainer>
          <MDBRow className='pt-5'>
            <MDBCol md='1'>
              <i class='fas fa-chevron-left fa-lg  mt-3 mb-4 pointer' onClick={() => this.props.history.push('/our-laundries')}></i>
            </MDBCol>
            <MDBCol md='11'>
              <div className='h6-responsive  mb-5 text-left mt-2'>{ReactHtmlParser(selectedLaundryProject.description)}</div>
            </MDBCol>
          </MDBRow>
          <div
            style={{
              paddingBottom: '5rem'
            }}
          >
            <CarouselComponent projectTypeArray={ProjectArray} selectedTypeProject={selectedLaundryProject} />
            <div className='text-left mt-lg-5 mt-5'>
              <MDBBtn color='secondary' className='text-left mt-lg-5 mt-2' onClick={() => this.props.history.push(`/our-laundries`)}>
                Back
              </MDBBtn>
            </div>
          </div>
        </MDBContainer>
      </>
    )
  }
}
export default withRouter(LaundryItem)
