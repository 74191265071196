export const ProjectArray = [
  {
    id: 1,
    name: "Te Kura",
    description:
      "No expense has been spared on this luxurious and elegant kitchen. High gloss white cabinetry, Alba stone benchtops and tiled splash backs all combine to create one of the brightest airiest kitchens we have installed to date. There is no shortage of bench space or storage here and with plenty of seating, it is perfect for entertaining. If you want to go all out on your new kitchen, this kind of look is easy to achieve and with our great range of colours and finishes, you can really make it your own.",
    coverImgUrl: require("../../assets/img/projects/te-kura/tekura1.jpg"),
    imgUrl: [
      "te-kura/tekura4.jpg",
      "te-kura/tekura5.jpg",
      "te-kura/tekura8.jpg",
      "te-kura/tekura3.jpg",
      "te-kura/tekura1.jpg",
      "te-kura/tekura6.jpg",
      "te-kura/tekura2.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 2,
    name: "All the Bench Space",
    description:
      "Another townhouse kitchen with limited floor space, this design was weighted by the clients desire to have maximum bench space possible for the area available. With a scullery/laundry area included in the designs we were able to fit the majority of the kitchen with under bench cabinetry and include high open faced shelving in the scullery to provide as much storage as possible while meeting the need for work space. We were also happy to provide shelving and cabinetry in the living area to match and create a cohesive look throughout the ground floor living areas. The space is inviting with Southern Oak cabinetry and warm white lighting above the Alba stone benchtop.",
    coverImgUrl: require("../../assets/img/projects/all-the-bench-space/unit 3-11.jpg"),
    imgUrl: [
      "all-the-bench-space/unit 3-11.jpg",
      "all-the-bench-space/unit 3-13.jpg",
      "all-the-bench-space/unit 3-19.jpg",
      "all-the-bench-space/unit 3-15.jpg",
      "all-the-bench-space/unit 3-14.jpg",
      "all-the-bench-space/unit 3-16.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 3,
    name: "Entertaining in a Small Space",
    description:
      "This compact kitchen is designed for entertaining. The scullery, packed with storage, bench space and including a second sink, is tucked away in the corner with a sliding door to keep prying eyes out. The long Alba stone breakfast bar provides seating space for up to 6 guests without crowding the kitchen and extra storage can be seen in the back. High open shelving allows for the display of items of interest. This kitchen is very compact but lacks none of the modern elements you would expect, such as a double door fridge space as well as dishwasher and double sink.",
    coverImgUrl: require("../../assets/img/projects/entertaining-in-a-small-space/unit 5-4.jpg"),
    imgUrl: [
      "entertaining-in-a-small-space/unit 5-1.jpg",
      "entertaining-in-a-small-space/unit 5-2.jpg",
      "entertaining-in-a-small-space/unit 5-7.jpg",
      "entertaining-in-a-small-space/unit 5-4.jpg",
      "entertaining-in-a-small-space/unit 5-8.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 4,
    name: "High-end Finish on a Budget",
    description:
      "This kitchen is a great example of how, with the right design, a high-end finish can be achieved using mid-range materials. The black negative detail on the back of the breakfast bar and the black glass splash-back both bring out the grey marbling in the Superwhite stone benchtop and the waterfall ends create a seamless flow into the living area of this bright and airy space. Brushed steel fittings on the cabinetry blend beautifully with the stainless-steel appliances. Despite its size, this kitchen feels open and comfortable to move around in and the design ensures that it doesn't impose itself onto the rest of the house.",
    coverImgUrl: require("../../assets/img/projects/high-end-finish-on-a-budget/IMG_4584 wide top and splash back - Copy.JPG"),
    imgUrl: [
      "high-end-finish-on-a-budget/IMG_4584 wide top and splash back - Copy.JPG",
      "high-end-finish-on-a-budget/IMG_4606 length.JPG",
      "high-end-finish-on-a-budget/IMG_4579 high island from rear - Copy.JPG",
      "high-end-finish-on-a-budget/IMG_4594 oven module close.JPG",
      "high-end-finish-on-a-budget/IMG_4605 from loounge opposite.JPG",
      "high-end-finish-on-a-budget/IMG_4600 from lounge wide - Copy.JPG",
      "high-end-finish-on-a-budget/IMG_4580 sink and top - Copy.JPG",
    ],
    type: "kitchen",
  },
  {
    id: 5,
    name: "Laundries",
    description:
      "Let our design team help you to find a functional solution to your new laundry or renovation. Whether it is a part of your garage or its own separate room, make it work for you. We have storage solutions to meet every need and space requirement. Check out our Storage and Accessories page to find out how we can make to most of your space.",
    coverImgUrl: require("../../assets/img/projects/laundries/IMG_4554 laundry top.JPG"),
    imgUrl: [
      "laundries/laundry-2.jpg",
      "laundries/laundry-3.jpg",
      "laundries/laundry-4.jpg",
      "laundries/laundry-5.jpg",
      "laundries/IMG_4554 laundry top.JPG",
      "laundries/IMG_4033 laundry top and sink.JPG",
      "laundries/laundry 1.jpeg",
      "laundries/IMG_4030 laundry high.JPG",
      "laundries/IMG_4557 laundry low front.JPG",
    ],
    type: "laundry",
  },
  {
    id: 6,
    name: "Vanities",
    description:
      "Here at Current Kitchens we offer a variety of products and services, not just kitchens. Add a touch of elegance to your bathroom with one of our vanities. With your choice of looks ranging from something with simple clean lines to opulent stone topped, floor standing or wall mounted,  and any sink profile you can think of, we can help you achieve the look you are dreaming of.",
    coverImgUrl: require("../../assets/img/projects/vanities/IMG_3988 vanity side.JPG"),
    imgUrl: [
      "vanities/IMG_3988 vanity side.JPG",
      "vanities/vanity double bowl front.jpg",
      "vanities/IMG_6328.JPG",
      "vanities/IMG_6329.JPG",
      "vanities/vanity square sink front.jpg",
      "vanities/IMG_3983 vanity close.JPG",
      "vanities/IMG_6414.JPG",
      "vanities/IMG_3992 vanity low front.JPG",
      "vanities/vanity square sink.jpg",
      "vanities/IMG_4628 vanitu birds eye.JPG",
      "vanities/IMG_6416.JPG",
      "vanities/IMG_3995 vanity storage and detail.JPG",
      "vanities/IMG_4632 vanity close up.JPG",
      "vanities/IMG_6342.JPG",
      "vanities/IMG_3994 va nity birds eye.JPG",
      "vanities/IMG_6421.JPG",
      "vanities/vanity double bowl.jpg",
      "vanities/IMG_6420.jpg",
      "vanities/IMG_4625 bathroom entrance.JPG",
      "vanities/IMG_6333.JPG",
      "vanities/IMG_8837.JPG",
      "vanities/IMG_9067.JPG",
    ],
    type: "vanities",
  },
  {
    id: 7,
    name: "Storage and Accessories",
    description:
      "Gone are the days of putting your back out reaching into the depths of those under-bench cupboards to find that flower vase or cake tin that you have not used in months, or that tin of tomatoes on the top shelf of your pantry that expired years ago. With our variety of storage solutions, you can make your kitchen or laundry the most functional room in the house. We have a range of pull-outs and inserts for cupboards and drawers that make storing and organising things a breeze. Let us know what your needs are and we can take you through our range of options.",
    coverImgUrl: require("../../assets/img/projects/storage-and-accessories/IMG_3593.jpg"),
    imgUrl: [
      "storage-and-accessories/IMG_3593.jpg",
      "storage-and-accessories/IMG_3627.jpg",
      "storage-and-accessories/IMG_3624.jpg",
      "storage-and-accessories/IMG_3591.jpg",
      "storage-and-accessories/IMG_3600.jpg",
      "storage-and-accessories/IMG_3614.jpg",
      "storage-and-accessories/IMG_3617.jpg",
      "storage-and-accessories/IMG_3612.jpg",
      "storage-and-accessories/IMG_3599.jpg",
      "storage-and-accessories/IMG_3604.jpg",
      "storage-and-accessories/IMG_4501 storage.JPG",
      "storage-and-accessories/IMG_3662.jpg",
      "storage-and-accessories/garage laundry storage 1.jpeg",
      "storage-and-accessories/IMG_3670.jpg",
      "storage-and-accessories/wardrobe 1.jpeg",
      "storage-and-accessories/IMG_3671.jpg",
      "storage-and-accessories/warbrobe basic 1.jpeg",
      "storage-and-accessories/IMG_9092.JPG",
      "storage-and-accessories/IMG_9082.JPG",
    ],
    type: "storage",
  },
  {
    id: 8,
    name: "Bryndwyr",
    description:
      "A few simple design ideas can make a basic white kitchen look luxurious and unique, such as in this kitchen where our client created an art deco feel by mixing the geometric lines of the splashback with soft globe lights and a marble look stone benchtop. This kitchen looks modern and will age beautifully.",
    coverImgUrl: require("../../assets/img/projects/bryndwyr/bryndwyr-1.jpg"),
    imgUrl: [
      "bryndwyr/bryndwyr-1.jpg",
      "bryndwyr/bryndwyr-2.jpg",
      "bryndwyr/bryndwyr-3.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 9,
    name: "Light, Dark and Neutral",
    description:
      "Keep things simple and elegant with a mix light wood and dark matt finish cabinetry and light benchtop. This combination will blend seamlessly into almost any new home and is easy to personalise. The light timber effect on the upper cabinets lifts the space and keeps things feeling open while the dark matt effect on the lower elements is very easy to keep clean and provides a subtle negative space effect",
    coverImgUrl: require("../../assets/img/projects/light-dark-and-neutral/random 1.jpg"),
    imgUrl: [
      "light-dark-and-neutral/random 1.jpg",
      "light-dark-and-neutral/random 2.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 25,
    name: "Bayliss",
    description:
      "Sleek and modern. This high contrast kitchen is styled in blackened oak, off white and stone. Accented with LED strip lights, it gives off a luxurious and inviting effect. Especially in the scullery tucked away at the end, the strip lights make the space functional and accessible. The owner has a great eye and their addition of natural timber light shades really sets the whole thing off.",
    coverImgUrl: require("../../assets/img/projects/bayliss/Bay2.JPG"),
    imgUrl: [
      "bayliss/Bay8.JPG",
      "bayliss/Bay9.JPG",
      "bayliss/Bay4.JPG",
      "bayliss/Bay5.JPG",
      "bayliss/Bay7.JPG",
      "bayliss/Bay6.JPG",
      "bayliss/Bay2.JPG",
    ],
    type: "kitchen",
  },
  {
    id: 10,
    name: "Edgeware",
    description:
      "At this new development we have 3 beautiful contemporary kitchens. All with timber finish on the cabinetry and neutral stone benchtops. This kind of design will age well and allow whoever moves in to put their own stamp on the space.",
    coverImgUrl: require("../../assets/img/projects/edgeware/Developement Cover.JPG"),
    imgUrl: [],
    multipleProjects: [
      {
        name: "Unit 1",
        description:
          "At this new development we have 3 beautiful contemporary kitchens. All with timber finish on the cabinetry and neutral stone benchtops. This kind of design will age well and allow whoever moves in to put their own stamp on the space.",
        imgUrl: [
          "edgeware/unit-1/IMG_8707.JPG",
          "edgeware/unit-1/IMG_8708.JPG",
          "edgeware/unit-1/IMG_8712.JPG",
          "edgeware/unit-1/IMG_8726.JPG",
          "edgeware/unit-1/IMG_8745.JPG",
          "edgeware/unit-1/IMG_8763.JPG",
        ],
      },
      {
        name: "Unit 2",
        description: "",
        imgUrl: [
          "edgeware/unit-2/IMG_8893.JPG",
          "edgeware/unit-2/IMG_8903.JPG",
          "edgeware/unit-2/IMG_8909.JPG",
          "edgeware/unit-2/IMG_8912.JPG",
          "edgeware/unit-2/IMG_8917.JPG",
          "edgeware/unit-2/IMG_8919.JPG",
          "edgeware/unit-2/IMG_8928.JPG",
          "edgeware/unit-2/IMG_8929.JPG",
          "edgeware/unit-2/IMG_8933.JPG",
          "edgeware/unit-2/IMG_8937.JPG",
          "edgeware/unit-2/IMG_8945.JPG",
        ],
      },
      {
        name: "Unit 3",
        description: "",
        imgUrl: [
          "edgeware/unit-3/IMG_9031.JPG",
          "edgeware/unit-3/IMG_9036.JPG",
          "edgeware/unit-3/IMG_9038.JPG",
          "edgeware/unit-3/IMG_9046.JPG",
          "edgeware/unit-3/IMG_9050.JPG",
        ],
      },
    ],
    type: "kitchen",
  },
  {
    id: 11,
    name: "Straven",
    description:
      "This is a beautifully finished high-end kitchen, no expense has been spared and it shows. It oozes luxury and modernity.",
    coverImgUrl: require("../../assets/img/projects/straven/straven_1.jpg"),
    imgUrl: [
      "straven/straven_1.jpg",
      "straven/straven_2.jpg",
      "straven/straven_3.jpg",
      "straven/straven_4.jpg",
      "straven/straven_5.jpg",
      "straven/straven_6.jpg",
      "straven/straven_7.jpg",
      "straven/straven_8.jpg",
      "straven/straven_9.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 12,
    name: "Nepal",
    description:
      "The kitchen at Nepal place was a team effort between our designer and the homeowner who has exquisite taste. Brushed chrome sink fittings blend quietly into the background allowing the monochromatic splendour of this kitchen to really come to the forefront. The owners choice of brass and glass drop lights over the island as a statement piece and a matt textured tile splashback in a neutral tone both add to the overall effect of the space without forcing themselves into your awareness. Our vote for top kitchen of 2022.",
    coverImgUrl: require("../../assets/img/projects/nepal/portfolio 2.JPG"),
    imgUrl: [
      "nepal/portfolio 1.JPG",
      "nepal/portfolio 2.JPG",
      "nepal/portfolio 3.JPG",
      "nepal/portfolio 4.JPG",
      "nepal/portfolio 5.JPG",
      "nepal/portfolio 6.JPG",
      "nepal/portfolio 7.JPG",
      "nepal/portfolio 8.JPG",
      "nepal/portfolio 9.JPG",
      "nepal/portfolio 10.JPG",
    ],
    type: "kitchen",
  },
  {
    id: 13,
    name: "Oakville",
    description:
      "This modern, stylish kitchen adds warmth and a homely feel to the crisp white look of this new build. There is no shortage of bench space or storage with a walk-in pantry conveniently located at the rear of the kitchen. The laundry room has been styled in line with the rest of the house and has a clean and fresh feel.",
    coverImgUrl: require("../../assets/img/projects/oakville/1.jpg"),
    imgUrl: [
      "oakville/1.jpg",
      "oakville/2.jpg",
      "oakville/3.jpg",
      "oakville/4.jpg",
      "oakville/5.jpg",
      "oakville/6.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 14,
    name: "Stylish Family Living",
    description:
      "This stunning kitchen features a double height ceiling with generously sized windows above, allowing plenty of light into the space and is beautifully appointed, with counters and island breakfast bar in Fusco concrete. The island is further enhanced by Batton 25 feature panels in Resene Ironsand, finished in 10% lacquer coating. Cabinets in Blackened Legno Organic with additions of Carbon Velvet and Snowdrift Satin. Chic barn-style doors connect to the spacious scullery fitted with Alba engineered stone. Black composite sinks throughout blend seamlessly with the designer’s chosen palette and a separate wine rack and bar fridge nook with floating shelves add a touch of luxury to this inviting and liveable main kitchen. This house also features a fully plumbed bar in the Master bedroom and a separate guest apartment with it's own kitchen, both fitted with the same Blackened Legno Organic cabinetry and led lighting throughout.",
    coverImgUrl: require("../../assets/img/projects/stylish-family-living/sfl_1.jpg"),
    imgUrl: [
      "stylish-family-living/sfl_1.jpg",
      "stylish-family-living/sfl_2.jpg",
      "stylish-family-living/sfl_3.jpg",
      "stylish-family-living/sfl_4.jpg",
      "stylish-family-living/sfl_5.jpg",
      "stylish-family-living/sfl_6.jpg",
      "stylish-family-living/sfl_7.jpg",
      "stylish-family-living/sfl_8.jpg",
      "stylish-family-living/sfl_9.jpg",
      "stylish-family-living/sfl_10.jpg",
      "stylish-family-living/sfl_11.jpg",
      "stylish-family-living/sfl_12.jpg",
      "stylish-family-living/sfl_13.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 15,
    name: "Who said black was slimming",
    description:
      "These floor to ceiling profiled panels in matt black add interest to this living area without taking away from its spacious feel. Subtle grey keeps the kitchen light and airy. Curved edges on the island add to the easy flow of the overall space. A separate laundry off the kitchen with additional storage is a great way to keep the kitchen tidy with the added bonus of being able to shut the door on noisy appliances.",
    coverImgUrl: require("../../assets/img/projects/springfield/sfl_1.jpg"),
    imgUrl: [
      "springfield/sfl_1.jpg",
      "springfield/sfl_2.jpg",
      "springfield/sfl_3.jpg",
      "springfield/sfl_4.jpg",
      "springfield/sfl_5.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 16,
    name: "City living at it's best",
    description:
      "In this open plan townhouse we offer up a modern and stylish kitchen. With graphite cabinetry, grey and white marbled stone tops with matching splashback and anthracite handles for a subtle accent. This kitchen is monochrome at its best.",
    coverImgUrl: require("../../assets/img/projects/geraldine/sfl_1.jpg"),
    imgUrl: [
      "geraldine/sfl_1.jpg",
      "geraldine/sfl_2.jpg",
      "geraldine/sfl_3.jpg",
      "geraldine/sfl_4.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 17,
    name: "Room for the whole family",
    description:
      "When you need room for the whole family it’s nice to create a bit of variety between spaces. Our designer has worked with the homeowners to define different spaces throughout the home, giving each room its own character. With 5 small children and 2 working parents, functionality is a must. A large island provides plenty of space to seat the whole family in the kitchen and lots of open storage in the laundry helps to keep things organised on washing day. The dark finish in the office adds a nice touch of masculinity to his home office.",
    coverImgUrl: require("../../assets/img/projects/latest/3-oak-room-for-the-whole-family/1.jpg"),
    imgUrl: [
      "latest/3-oak-room-for-the-whole-family/1.jpg",
      "latest/3-oak-room-for-the-whole-family/2.jpg",
      "latest/3-oak-room-for-the-whole-family/3.jpg",
      "latest/3-oak-room-for-the-whole-family/4.jpg",
      "latest/3-oak-room-for-the-whole-family/5.jpg",
      "latest/3-oak-room-for-the-whole-family/6.jpg",
      "latest/3-oak-room-for-the-whole-family/7.jpg",
      "latest/3-oak-room-for-the-whole-family/8.jpg",
      "latest/3-oak-room-for-the-whole-family/9.jpg",
      "latest/3-oak-room-for-the-whole-family/10.jpg",
      "latest/3-oak-room-for-the-whole-family/11.jpg",
      "latest/3-oak-room-for-the-whole-family/12.jpg",
      "latest/3-oak-room-for-the-whole-family/13.jpg",
      "latest/3-oak-room-for-the-whole-family/14.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 18,
    name: "Architectually designed",
    description:
      "Our team worked hard to install joinery into these new townhouses in exact accordance with the architects vision. Supplying kitchen, laundry and wardrobe joinery as well as an office space and bathroom vanities.",
    coverImgUrl: require("../../assets/img/projects/latest/29-ton-architectually-designed/1.jpg"),
    imgUrl: [
      "latest/29-ton-architectually-designed/1.jpg",
      "latest/29-ton-architectually-designed/2.jpg",
      "latest/29-ton-architectually-designed/3.jpg",
      "latest/29-ton-architectually-designed/4.jpg",
      "latest/29-ton-architectually-designed/5.jpg",
      "latest/29-ton-architectually-designed/6.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 19,
    name: "Hamilton",
    description:
      "This design has clean lines and neutral tones, allowing anyone to put a bit of their own personality into the space. But with just a hint of industrial edginess the space holds it own beautifully.",
    coverImgUrl: require("../../assets/img/projects/latest/112-ham-hamilton/1.jpg"),
    imgUrl: [
      "latest/112-ham-hamilton/1.jpg",
      "latest/112-ham-hamilton/2.jpg",
      "latest/112-ham-hamilton/3.jpg",
      "latest/112-ham-hamilton/4.jpg",
      "latest/112-ham-hamilton/5.jpg",
      "latest/112-ham-hamilton/6.jpg",
      "latest/112-ham-hamilton/7.jpg",
      "latest/112-ham-hamilton/8.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 20,
    name: "Barnfield",
    description:
      "The Green Slate cabinetry fronts used alongside a warm wood grain panel in this kitchen gives a very organic and cosy feel to this huge open plan space making it very inviting. The clean lines and neutral benchtop colour keep it modern and fresh. The centerpiece is a beautiful oversized island with waterfall ends and LED lights tucked under the breakfast bar overhang. These create a light and airy but also warm feeling even on cold winter days.",
    coverImgUrl: require("../../assets/img/projects/latest/barnfield/1.jpg"),
    imgUrl: [
      "latest/barnfield/1.jpg",
      "latest/barnfield/2.jpg",
      "latest/barnfield/3.jpg",
      "latest/barnfield/4.jpg",
      "latest/barnfield/5.jpg",
      "latest/barnfield/6.jpg",
      "latest/barnfield/7.jpg",
      "latest/barnfield/8.jpg",
    ],
    type: "kitchen",
  },
  {
    id: 30,
    name: "Cornwall",
    description: `To fit with a modernised Character Villa, we developed a kitchen design that bridged the gap between old and new: taking inspiration from a classic shaker style but by reducing the width of the shaker panel, we created a more contemporary look. While still giving a nod to the original heritage feel. 
      The soft Marble veins on the bench top, the boxed in waterfall end and splashback, are balanced by the light grey cabinetry and strikingly modern dark woodgrain feature panelling with fluted glass doors, giving a subtle separation between the kitchen & dining area. The antique brass Berkley handles off-set the neutral colours and really make everything pop. 
      Utilising empty space under the stairs, we created a built-in bar to bring that area to life. Using antique mirrored glass along the back to draw your attention and a moody colour to invite you in. We topped it off with a copper bench top which gives an industrial feel and created a unique wine rack feature.`,
    coverImgUrl: require("../../assets/img/projects/latest/cornwall/1.jpg"),
    imgUrl: [
      "latest/cornwall/1.jpg",
      "latest/cornwall/2.jpg",
      "latest/cornwall/3.jpg",
      "latest/cornwall/4.jpg",
      "latest/cornwall/5.jpg",
      "latest/cornwall/6.jpg",
      "latest/cornwall/7.jpg",
      "latest/cornwall/8.jpg",
      "latest/cornwall/9.jpg",
      "latest/cornwall/10.jpg",
      "latest/cornwall/11.jpg",
    ],
    type: "kitchen",
  },
  // {
  //   id: 4,
  //   name: 'Compact and Inviting',
  //   description:
  //     "Just because you are downsizing or are limited for space, doesn't mean you can't have a wonderful kitchen. This kitchen is small but it doesn't feel confined. There is enough space to provide ample storage, warm wood finish on the cabinetry makes the small space feel cosy and light benchtops prevent it becoming claustrophobic. The overall effect combines to give this small kitchen a very modern classic feel.",
  //   coverImgUrl: require('../../assets/img/projects/compact-and-inviting/1CoverIMG_3499.jpg'),
  //   imgUrl: ['compact-and-inviting/1CoverIMG_3499.jpg', 'compact-and-inviting/2introIMG_3543.jpg', 'compact-and-inviting/3close upIMG_3494.jpg', 'compact-and-inviting/4fullbodyIMG_3509.jpg', 'compact-and-inviting/5closeupbodyIMG_3529.jpg'],
  //   type: 'kitchen'
  // },
  // {
  //   id: 5,
  //   name: 'Clean Lines and a Simple Design',
  //   description:
  //     'This little kitchen was done for a new block of townhouses, the developer specified a simple design with clean lines and a modern feel. The dark grey is lovely contrast to the ultra-white of the rest of the house and is beautifully accented by brass fittings. The unassuming style of the kitchen will allow any new owner to easily put their own stamp on the space and make it their own.',
  //   coverImgUrl: require('../../assets/img/projects/clean-lines-and-a-simple-design/4493 tops.JPG'),
  //   imgUrl: [
  //     'clean-lines-and-a-simple-design/4493 tops.JPG',
  //     'clean-lines-and-a-simple-design/IMG_4512 from entry.JPG',
  //     'clean-lines-and-a-simple-design/IMG_4497 island.JPG',
  //     'clean-lines-and-a-simple-design/IMG_4520 from lounge.JPG',
  //     'clean-lines-and-a-simple-design/IMG_4532 oven module.JPG',
  //     'clean-lines-and-a-simple-design/IMG_4525island low.JPG'
  //   ],
  //   type: 'kitchen'
  // },

  // {
  //   id: 7,
  //   name: "Warm and Modern",
  //   description:
  //     "This kitchen is one of 4 townhouses in the central city. These designs focused on maximising space and in the particular unit we chose warm toned walnut finish cabinetry to create a cosy feeling environment. The white subway tiles in the splashback and stone benchtop and waterfall end keep the look modern and clean. Extra storage comes in the form of hidden cupboards in the back of the breakfast bar allowing for maximum use of space.",
  //   coverImgUrl: require("../../assets/img/projects/warm-and-modern/unit 1-3.jpg"),
  //   imgUrl: [
  //     "warm-and-modern/unit 1-3.jpg",
  //     "warm-and-modern/unit 1-2.jpg",
  //     "warm-and-modern/unit 1-1.jpg",
  //     "warm-and-modern/unit 1-9.jpg",
  //     "warm-and-modern/unit 1-8.jpg",
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 8,
  //   name: 'Bishop',
  //   description:
  //     'Sometimes simplicity is the best route to take. This low-key white on white look will stay clean and current through trend after trend. The basic black handles help to break things up and keep the eye interested. Containing all the mod-cons including stainless steel appliances and plenty of storage, anybody could make themselves at home here.',
  //   coverImgUrl: require('../../assets/img/projects/bishop/bishop1.jpg'),
  //   imgUrl: ['bishop/bishop3.jpg', 'bishop/bishop2.jpg', 'bishop/bishop1.jpg'],
  //   type: 'kitchen'
  // },
  // {
  //     id: 9,
  //     name: "Other design ideas",
  //     description:
  //         "Here are a few examples of how different choices can make really bold statements when designing your new kitchen. Step outside of your comfort zone and create a unique space where your personality can really shine. Talk our friendly design team about options you might not have considered and make your new kitchen the talking point in your house.  An individual piece of functional art that you can enjoy every day.",
  //     coverImgUrl: require("../../assets/img/projects/other-design-ideas/od_1.jpg"),
  //     imgUrl: [
  //         "other-design-ideas/od_9 copy.jpg",
  //         "other-design-ideas/od_8.jpg",
  //         "other-design-ideas/od_12.jpg",
  //         "other-design-ideas/od_2.jpg",
  //         "other-design-ideas/od_7 copy.jpg",
  //         "other-design-ideas/od_6 copy.jpg",
  //         "other-design-ideas/od_3.jpg",
  //         "other-design-ideas/od_1.jpg",
  //         "other-design-ideas/od_1.png"
  //     ],
  //     type: "kitchen"
  // },
  // {
  //   id: 9,
  //   name: "Like a Picture",
  //   description:
  //     "In this project, our clients wanted a bright and airy open plan kitchen with a high-end finish. In this space we used dark wood finish on the rear wall and island back panel to define the kitchen area while the light colour stone benchtops, tiled splashback and cabinetry keep the area feeling open and bright. The Stone Benchtop is triangular in shape with an apron at one end and a mitred waterfall at the other to make the most of the available space and guide visitors into the living area. <br><br> Adjustable shelving with benchtops in the walk-in pantry ensure the storage space is practical for any user and keeps unsightly small appliances out of sight. This all combines to create a wonderful entertaining area that still meets the needs of any domestic chef.",
  //   coverImgUrl: require("../../assets/img/projects/like-a-picture/IMG_6961 edit.jpg"),
  //   imgUrl: [
  //     "like-a-picture/IMG_6961 edit.jpg",
  //     "like-a-picture/IMG_6960 edit.jpg",
  //     "like-a-picture/IMG_7023 edit.png",
  //     "like-a-picture/IMG_7008 edit.jpg",
  //     "like-a-picture/IMG_6965 edit.jpg",
  //     "like-a-picture/IMG_6980 edit.jpg",
  //     "like-a-picture/IMG_7019 edit.JPG",
  //     "like-a-picture/IMG_7012 edit.jpg",
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 10,
  //   name: "A little bit of attitude",
  //   description:
  //     "This kitchen is very modern, and it has its very own personality with a little bit of attitude. These cool dark granite tones and brushed brass fittings are high impact, contrasting against the brilliant white of the rest of the living area. It defines the kitchen from other spaces and draws you in. If you are looking to jazz up your forever home ort investment then bold colour choices in kitchen could be just what you are looking for.",
  //   coverImgUrl: require("../../assets/img/projects/a-little-bit-of-attitude/att_29.jpg"),
  //   imgUrl: [
  //     "a-little-bit-of-attitude/att_28.jpg",
  //     "a-little-bit-of-attitude/att_29.jpg",
  //     "a-little-bit-of-attitude/att_27.jpg",
  //     "a-little-bit-of-attitude/att_26.jpg",
  //     "a-little-bit-of-attitude/att_32.jpg",
  //     "a-little-bit-of-attitude/att_30.jpg",
  //     "a-little-bit-of-attitude/att_31.jpg",
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 11,
  //   name: 'White Neutral',
  //   description:
  //     'If you are looking to keep things neutral in your new kitchen, white and timber are a great combination that everyone will love. Keep things interesting with bold fittings such as the brushed brass handles in this kitchen.',
  //   coverImgUrl: require('../../assets/img/projects/white-neutral/wn_20.jpg'),
  //   imgUrl: ['white-neutral/wn_21.jpg', 'white-neutral/wn_20.jpg', 'white-neutral/wn_22.jpg', 'white-neutral/wn_23.jpg', 'white-neutral/wn_24.jpg', 'white-neutral/wn_4.jpg', 'white-neutral/wn_5.jpg', 'white-neutral/wn_3.jpg'],
  //   type: 'kitchen'
  // },
  // {
  //   id: 12,
  //   name: "Warm and Neutral",
  //   description:
  //     "This is a nice classic look with warm neutral tones. Great for new builds that have not yet been sold, as the style allows new owners or tenants to make it their own. Simple fittings, light colours and all stainless steel appliances keep this kitchen looking clean and modern.",
  //   coverImgUrl: require("../../assets/img/projects/warm-and-neutral/wn_2introIMG_3543.jpg"),
  //   imgUrl: [
  //     "warm-and-neutral/wn_1CoverIMG_3499.jpg",
  //     "warm-and-neutral/wn_2introIMG_3543.jpg",
  //     "warm-and-neutral/wn_3close upIMG_3494.jpg",
  //     "warm-and-neutral/wn_4fullbodyIMG_3509.jpg",
  //     "warm-and-neutral/wn_5closeupbodyIMG_3529.jpg",
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 13,
  //   name: "Out of this world",
  //   description:
  //     "This stylish black on black kitchen is out of this world. The high gloss Nero Piatto stone top is beautifully complimented by a combination of cool neutral wood finish on the back of the breakfast bar and high cabinets, matt black cabinetry and fittings and a textured white tile splash back. Despite it's ultra modern finish this kitchen has an almost art deco feel to it.",
  //   coverImgUrl: require("../../assets/img/projects/out-of-this-world/ot_1.JPG"),
  //   imgUrl: [
  //     "out-of-this-world/ot_8.JPG",
  //     "out-of-this-world/ot_4.JPG",
  //     "out-of-this-world/ot_5.JPG",
  //     "out-of-this-world/ot_7.JPG",
  //     "out-of-this-world/ot_6.JPG",
  //     "out-of-this-world/ot_2.JPG",
  //     "out-of-this-world/ot_3.JPG",
  //     "out-of-this-world/ot_1.JPG",
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 14,
  //   name: "Semi-industrial",
  //   description:
  //     "The polished concrete and bold lighting choices in this kitchen give it an almost industrial feel. The warm tones in the wood finish keep things cosy and welcoming and LED light strips throw shadows on geometric tiles in the splashback creating points of interest that draw the eye. This kitchen is designed for entertaining with an under bench wine rack and fridge built into the breakfast bar for easy access.",
  //   coverImgUrl: require("../../assets/img/projects/semi-industrial/si_1.JPG"),
  //   imgUrl: [
  //     "semi-industrial/si_8.JPG",
  //     "semi-industrial/si_4.JPG",
  //     "semi-industrial/si_5.JPG",
  //     "semi-industrial/si_7.JPG",
  //     "semi-industrial/si_6.JPG",
  //     "semi-industrial/si_2.JPG",
  //     "semi-industrial/si_3.JPG",
  //     "semi-industrial/si_1.JPG",
  //   ],
  //   type: "kitchen",
  // },

  // {
  //   id: 20,
  //   name: "Gordon Ave",
  //   description:
  //     "When considering a new kitchen, don't feel that everything must be replaced or remodelled. If there are elements of your old kitchen that you love, talk to our designers. Often they can find ways to incorporate your favourite pieces into a new design, providing you with a truly unique space.",
  //   coverImgUrl: require("../../assets/img/projects/gordon-ave/2-IMG_6683.JPG"),
  //   imgUrl: [
  //     "gordon-ave/5-IMG_6692.JPG",
  //     "gordon-ave/11-IMG_6721.JPG",
  //     "gordon-ave/3-IMG_6687.JPG",
  //     "gordon-ave/7-IMG_6710.JPG",
  //     "gordon-ave/2-IMG_6683.JPG",
  //     "gordon-ave/4-IMG_6756.JPG",
  //     "gordon-ave/8-IMG_6701.JPG",
  //     "gordon-ave/9-IMG_6698.JPG",
  //     "gordon-ave/1-IMG_6754.JPG",
  //     "gordon-ave/6-IMG_6693.JPG",
  //     "gordon-ave/10-IMG_6716.JPG",
  //     "gordon-ave/13-IMG_6722.JPG",
  //     "gordon-ave/12-IMG_6720.JPG",
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 21,
  //   name: "Godly Ave",
  //   description:
  //     "This beautiful white on white kitchen is located near the beach and our client wanted her surroundings reflected in the design of the house's interior. The warm tones of the jute and pine furniture and accessories are reflected in the brass fittings on the cabinetry, adding a touch of sunshine to this already bright and airy kitchen.",
  //   coverImgUrl: require("../../assets/img/projects/godly-ave/IMG_8227.JPG"),
  //   imgUrl: [
  //     "godly-ave/IMG_8218.JPG",
  //     "godly-ave/IMG_8227.JPG",
  //     "godly-ave/IMG_8241.JPG",
  //     "godly-ave/IMG_8243.JPG",
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 22,
  //   name: "Riverside",
  //   description:
  //     "Units at a new central city development. All units were designed differently depending on aspect and client needs and aesthetics.",
  //   coverImgUrl: require("../../assets/img/projects/riverside/unit-13/IMG_8012.JPG"),
  //   imgUrl: [],
  //   multipleProjects: [
  //     {
  //       name: "Unit 9",
  //       description:
  //         "This was one of 17 units at a new central city development. All units were designed differently depending on aspect and client needs and aesthetics. The timber veneer on the wall units compliment the light coloured island cabinetry and benchtop and channel handles ensure that no one is bumping into anything while they move about the space. The client for this particular unit works from home so a small office space has also been included in the kitchen.",
  //       imgUrl: [
  //         "riverside/unit-9/IMG_8064.JPG",
  //         "riverside/unit-9/IMG_8081.JPG",
  //         "riverside/unit-9/IMG_8087.JPG",
  //         "riverside/unit-9/IMG_8110.JPG",
  //         "riverside/unit-9/IMG_8118.JPG",
  //         "riverside/unit-9/IMG_8124.JPG",
  //         "riverside/unit-9/IMG_8131.JPG",
  //       ],
  //     },
  //     {
  //       name: "Unit 11",
  //       description:
  //         "This kitchen combines warm wood veneer, white and charcoal for an exceptionally modern finish. An overhang on the island benchtop allows anyone seated there to fit the legs underneath without have to turn to the side and a neutral glass splashback allows you to admire the whole kitchen without having your attention dragged away to focus on small details.",
  //       imgUrl: [
  //         "riverside/unit-11/IMG_7901.JPG",
  //         "riverside/unit-11/IMG_7842.JPG",
  //         "riverside/unit-11/IMG_7908.JPG",
  //         "riverside/unit-11/IMG_7844.JPG",
  //         "riverside/unit-11/IMG_7850.JPG",
  //         "riverside/unit-11/IMG_7891.JPG",
  //       ],
  //     },
  //     {
  //       name: "Unit 13",
  //       description:
  //         "This stunning white on white kitchen is an entertainer's dream. No island means plenty of room for a dining table and the kitchen flows uninterrupted through to the living area. There is no shortage of storage space with three full length pantries and channel handles keep everything looking sleek.",
  //       imgUrl: [
  //         "riverside/unit-13/IMG_8012.JPG",
  //         "riverside/unit-13/IMG_8016.JPG",
  //         "riverside/unit-13/IMG_8025.JPG",
  //         "riverside/unit-13/IMG_8009.JPG",
  //         "riverside/unit-13/IMG_7982.JPG",
  //         "riverside/unit-13/IMG_8050.JPG",
  //         "riverside/unit-13/IMG_7992.JPG",
  //       ],
  //     },
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 23,
  //   name: "Angelic",
  //   description:
  //     "This gorgeous white on white kitchen is a breath of fresh air. The clean simple design allows you to really appreciate the accents provided by the brass fittings and tiled splashback. This elegant kitchen would look at home in any house.",
  //   coverImgUrl: require("../../assets/img/projects/angelic/jeff4.jpg"),
  //   imgUrl: [
  //     "angelic/jeff3.jpg",
  //     "angelic/jeff2.jpg",
  //     "angelic/jeff1.jpg",
  //     "angelic/jeff5.jpg",
  //     "angelic/jeff4.jpg",
  //   ],
  //   type: "kitchen",
  // },
  // {
  //   id: 36,
  //   name: "Profiled panels are in",
  //   description:
  //     "Trend alert! Profiled panels are in. This kitchen and bar area is great for entertaining and beautiful to boot. Subtle tones of green and grey keep the kitchen looking elegant and neutral. Allowing the bar with its black profiled panels and engineered stone top to really stand out.",
  //   coverImgUrl: require("../../assets/img/projects/hawksbury/sfl_1.jpg"),
  //   imgUrl: [
  //     "hawksbury/sfl_1.jpg",
  //     "hawksbury/sfl_2.jpg",
  //     "hawksbury/sfl_3.jpg",
  //     "hawksbury/sfl_4.jpg",
  //   ],
  //   type: "kitchen",
  // },
];
