import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBRow } from 'mdbreact'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { ProjectArray } from '../PortfolioModal/ProjectArray'

class CardComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMore: false
    }
  }
  handleClick() {
    this.setState({ showMore: true })
  }

  render() {
    const projects = ProjectArray.filter((project) => project.type === this.props.projectType).reverse()
    const projectItem = projects.slice(this.props.start, this.props.end).map((project) => (
      <>
        <MDBCol md='4'>
          <MDBCard
            className='grey-text mb-4'
            onClick={() => this.props.history.push(`/${this.props.projectType.toString().toLowerCase()}-item?id=${project.id}`)}
            style={{ minHeight: '555px' }}
          >
            <MDBCardHeader
              style={{
                backgroundImage: `url("${project.coverImgUrl}")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '15rem'
              }}
            ></MDBCardHeader>
            <MDBCardBody className='my-2'>
              <div
                className='h4-responsive mb-2 beige-text'
                style={{
                  textAlign: 'left'
                }}
              >
                {project.name}
              </div>
              <div
                style={{
                  textAlign: 'left',
                  height: '5rem'
                }}
              >
                {project.description.substring(0, 95)}..
              </div>
              <MDBBtn
                color='secondary'
                style={{
                  float: 'left',
                  bottom: '20px',
                  left: '20px',
                  position: 'absolute'
                }}
                onClick={() => this.props.history.push(`/${this.props.projectType.toString().toLowerCase()}-item?id=${project.id}`)}
              >
                View
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </>
    ))
    return (
      <>
        <div
          style={{
            paddingTop: '5rem'
          }}
        >
          <MDBContainer>
            <MDBRow className='mb-5 rubikRegular'>{projectItem}</MDBRow>
          </MDBContainer>
        </div>
      </>
    )
  }
}
export default withRouter(CardComponent)
