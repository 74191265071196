import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBIcon, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom';
import './style.css'
import { axRequest, METHOD_POST } from '../../utils/request';
import { SK, SUCCESS_COLOR, FAILED_COLOR } from '../../utils/constants';

class ContactUsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            sendMessage: '',
            color: '',
            data: {
                key: SK,
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
                extra: ''
            }
        }
    }

    submitHandler = async (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        let inputs = event.nativeEvent.target;
        for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];

            if (input.name === 'extra')
                continue;

            if (input != null && !input.validity.valid) {
                return;
            }
        }

        let s = this.state;
        s.processing = true;
        s.sendMessage = '';
        this.setState(s);

        let response = await axRequest(null, '/client-mailers', METHOD_POST, this.state.data);
        s = this.state;
        if (response && response.success) {
            s.color = SUCCESS_COLOR;
            s.sendMessage = 'Thanks for your message, we\'ll be in touch soon!'
            this.resetForm();
        } else {
            s.color = FAILED_COLOR;
            s.sendMessage = 'Oops, something went wrong, please try again!!'
        }
        s.processing = false;
        this.setState(s);
    }

    handleChange = (event) => {
        let s = this.state;
        s.data[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState(s);
    };

    resetForm = () => {
        document.getElementById("contact-form").classList.remove("was-validated");
        let s = this.state;
        s.data = {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        };

        this.setState(s);
    }

    render() {
        return (
            <div className="mx-auto">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Contact Us | Current Kitchens</title>
                    <link rel="canonical" href="https://www.currentkitchens.co.nz/contact-us" />
                </Helmet>
                <ScrollToTop />
                <div className="contact-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter">
                    <MDBCol md="6" className="header-text mx-auto text-left header-text-center">
                        <div className="pt-5">
                            <MDBAnimation reveal type="fadeInDown" className="fast">
                                <h1 className="h4-responsive beige-text text-uppercase mt-5 mb-2">Contact us</h1>
                            </MDBAnimation>
                            <MDBAnimation reveal type="fadeInUp" className="fast">
                                <h1 className="h1-responsive font-weight-md">Get in touch with us</h1>
                            </MDBAnimation>
                        </div>
                    </MDBCol>
                </div>
                <div id="contact" className="pt-5 pb-5">
                    <MDBContainer className="mt-5 mb-5 divider-margin">
                        <MDBRow className="mb-5">
                            <MDBCol md="12" lg="7" className="text-left mx-auto mb-5">
                                <h5 className="h3-responsive font-weight-md mb-2 mt-3 beige-text">Send us a message</h5>
                                <h5 className="h6-responsive mb-4 mt-3">Contact us to discuss your upcoming project or if you have design plans, send them through to us for a no-obligation quote. We don’t just design your kitchen, but transform a space where everyone would want to spend their time.</h5>
                                <MDBCard>
                                    <div className="text-center beige-text mt-4 mb-2">
                                        <div className="mb-3 mt-2 grey-ck-text">Write a message</div>
                                        <MDBIcon far icon="comment-alt" size="3x" />
                                    </div>
                                    <MDBCardBody>
                                        <form id="contact-form" className="needs-validation" onSubmit={this.submitHandler} noValidate>
                                            {
                                                this.state.sendMessage && this.state.sendMessage.length > 0 ? (
                                                    <MDBRow>
                                                        <MDBCol
                                                            style={{ minHeight: '45px', padding: '5px 0px 25px 0px', fontSize: '14px', textAlign: 'center'}}
                                                        >

                                                            <MDBAnimation type="bounceIn" duration="500ms">
                                                                <div style={{ padding: '15px 5px 0px 5px', fontWeight: 500, fontSize: '20px', color: this.state.color }}>
                                                                    {this.state.sendMessage}
                                                                </div>
                                                            </MDBAnimation>

                                                        </MDBCol>
                                                    </MDBRow>
                                                ) : null
                                            }
                                            <div className="form-group">
                                                <label htmlFor="name" className="grey-text">
                                                    Your name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    value={this.state.data.name}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.processing}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <label htmlFor="email" className="grey-text">
                                                    Your email
                                                </label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="form-control"
                                                    value={this.state.data.email}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.processing}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <label htmlFor="phone" className="grey-text">
                                                    Your phone number
                                                </label>
                                                <input
                                                    type="tel"
                                                    id="phone"
                                                    name="phone"
                                                    pattern="^(0\d\d{7}|02\d\d{6,12}|0800\d{5,12})$"
                                                    className="form-control"
                                                    value={this.state.data.phone}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.processing}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <label htmlFor="subject" className="grey-text">
                                                    Subject
                                                </label>
                                                <input
                                                    type="text"
                                                    id="subject"
                                                    name="subject"
                                                    className="form-control"
                                                    value={this.state.data.subject}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.processing}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                            <br />
                                            <div className="form-group">
                                                <label htmlFor="message" className="grey-text">
                                                    Your message
                                                </label>
                                                <textarea
                                                    type="text"
                                                    id="message"
                                                    name="message"
                                                    className="form-control"
                                                    rows="3"
                                                    value={this.state.data.message}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.processing}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ display: 'none' }}>
                                                <label htmlFor="message" className="grey-text">
                                                    Extra Info
                                                </label>
                                                <textarea
                                                    type="text"
                                                    id="extra"
                                                    name="extra"
                                                    className="form-control"
                                                    rows="3"
                                                    value={this.state.data.extra}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.processing}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                            <div className="text-center mt-4">
                                                <MDBBtn
                                                    color="secondary"
                                                    type="submit"
                                                    style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                                                    disabled={this.state.processing}
                                                >
                                                    Send message
                                                    {
                                                        !this.state.processing ? null :
                                                            (
                                                                <div
                                                                    className="spinner-border"
                                                                    style={{ width: '25px', height: '25px', marginLeft: '10px' }}
                                                                    role="status"
                                                                >
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            )
                                                    }
                                                </MDBBtn>
                                            </div>
                                        </form>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol sm="12" md="12" lg="4">
                                <div className="text-left">
                                    <h5 className="h4-responsive font-weight-md mb-4 mt-3 beige-text">Contact Details</h5>
                                    <p className="h6-responsive">
                                        <MDBIcon icon="phone" /><b className="font-weight-md ml-2"><a className="grey-text" href="tel:+6421355215">+64 21 355 215</a></b>
                                    </p>
                                    <p className="h6-responsive">
                                        <MDBIcon icon="envelope" /> <b className="font-weight-md ml-2"><a className="grey-text" href={"mailto:info@currentkitchens.co.nz?subject=Web Enquiry"}>info@currentkitchens.co.nz</a></b>
                                    </p>
                                    <p className="h6-responsive">
                                        <MDBIcon icon="globe" /> <b className="font-weight-md ml-2"><a className="grey-text" href="https://www.currentkitchens.co.nz" target="_blank" rel="noopener noreferrer">www.currentkitchens.co.nz</a></b>
                                    </p>
                                    <h5 className="h4-responsive font-weight-md mb-4 mt-5 beige-text">Our location</h5>
                                    <p className="h6-responsive">
                                        <MDBIcon icon="map-marker-alt" /> <b className="font-weight-md ml-2 grey-text">Christchurch, New Zealand</b>
                                    </p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </div>
        );
    }
}

export default withRouter(ContactUsPage);