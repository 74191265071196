import { MDBContainer } from 'mdbreact';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PortfolioModal extends Component {
    state = {
        modal2: false
    }

    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
        let clickedButton = "our-" + (this.props.title).toLowerCase();
        this.props.history.push(clickedButton);
        console.log(clickedButton);
    }


    render() {
        return (
            <MDBContainer className="grey-ck-text">
                <div className="h1-responsive grey-ck-text grey-border" onClick={this.toggle(2)}>{(this.props.title)}</div>
            </MDBContainer>
        );
    }
}

export default withRouter(PortfolioModal);