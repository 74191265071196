import {
  MDBAnimation,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React from "react";
import { Helmet } from "react-helmet";
import { Parallax } from "react-parallax";
import { withRouter } from "react-router-dom";
import { scrollTo } from "../../App";
import ScrollToTop from "../Nav/ScrollToTop";
import { KitchenArray } from "../PortfolioModal/kitchensArray";
import { LaundryArray } from "../PortfolioModal/LaundryArray";
import PortfolioModal from "../PortfolioModal/PortfolioModal";
import { StorageArray } from "../PortfolioModal/StorageArray";
import "./style.css";

class Home extends React.Component {
  state = {
    collapsed: false,
  };

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  scrollToGetStarted() {
    var testDiv = document.getElementById("get-started");
    window.scroll({
      top: testDiv.offsetTop - 25,
      behavior: "smooth",
    });
  }

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.handleTogglerClick}
      />
    );
    return (
      <div id="home" style={{ overflow: "hidden" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Current Kitchens | Bespoke Kitchen Specialists | Commercial and
            Residential
          </title>
          <meta
            name="description"
            content="We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch."
          />
          <link rel="canonical" href="https://www.currentkitchens.co.nz" />
        </Helmet>
        <ScrollToTop />
        <Parallax
          bgImage={require("../../assets/img/home_header.jpg")}
          bgImageAlt="current kitchens"
          // strength={800}
          className="page-header header-filter clear-filter dark-filter white-text home-img-margin"
        >
          <MDBContainer className="text-left pt-5">
            <div style={{ zIndex: "2", position: "relative" }}>
              <MDBCol md="5">
                <MDBAnimation reveal type="fadeInRight" className="fast">
                  <h1 className="display-h2 pt-5 text-uppercase font-rubikRegular">
                    We are Bespoke Kitchen Specialists
                  </h1>
                </MDBAnimation>
              </MDBCol>
              <MDBCol md="6">
                <h6 className="h5-responsive mt-3 mb-lg-4">
                  We specialise in the design and installation of bespoke
                  commercial and residential kitchens.
                </h6>
                <MDBBtn
                  color="secondary"
                  onClick={() => this.props.history.push("/contact-us")}
                >
                  Contact Us
                </MDBBtn>
                <MDBBtn
                  outline
                  color="secondary"
                  onClick={() => scrollTo("get-started")}
                >
                  Learn More
                </MDBBtn>
              </MDBCol>
            </div>
          </MDBContainer>

          <div style={{ minHeight: "95vh" }} />
        </Parallax>
        <MDBContainer className="pb-5 pt-5">
          <MDBRow className="pt-4 pb-5 mx-auto">
            <MDBCol
              md="12"
              className="text-left pt-6 pb-5 mx-auto"
              id="get-started"
            >
              <div className="text-center">
                <MDBAnimation reveal type="fadeInDown" className="fast">
                  <h2 className="h1-responsive mb-4 text-uppercase beige-text">
                    Our services
                  </h2>
                </MDBAnimation>
                <hr
                  className="grey accent-2 mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px" }}
                />
              </div>
              <div className="h6-responsive font-weight-md pt-3">
                We understand the importance involved in creating a functional
                space that suits your lifestyle. We are here to help in
                balancing the look, style and layout to ensure your kitchen is
                functional, easy to use, easy to clean and be the focal point of
                your home.
              </div>
              <div className="h6-responsive pt-3">
                Whether you love to cook, love to entertain or love spending
                time with your family, your kitchen will be the centrepiece of
                your home. We offer a full range of services from the design and
                build to the installation of your kitchen.
              </div>
            </MDBCol>
            <MDBContainer>
              <MDBRow className="mb-5 rubikRegular">
                <MDBCol md="4">
                  <MDBCard
                    className="grey-text mb-4"
                    style={{ minHeight: "485px" }}
                  >
                    <MDBCardHeader className="grey-bg">
                      <div className="h3-responsive mt-4 text-uppercase">
                        Design
                      </div>
                      <i className="fas fa-paint-brush beige-text mb-4 mt-3 fa-3x"></i>
                      <br />
                    </MDBCardHeader>
                    <MDBCardBody className="my-2">
                      <div className="h4-responsive mb-2 beige-text">
                        Step One
                      </div>
                      <div className="text-justify">
                        {" "}
                        We discover the look you would like to achieve for your
                        kitchen, recommending colours, door profiles, handles
                        and bench top materials complimentary to your personal
                        style and taste. You can virtually walk-through your
                        kitchen design before finalizing.
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol md="4">
                  <MDBCard
                    className="grey-text mb-4"
                    style={{ minHeight: "485px" }}
                  >
                    <MDBCardHeader className="grey-bg">
                      <div className="h3-responsive mt-4 text-uppercase">
                        Build
                      </div>
                      <i className="fas fa-ruler beige-text mb-4 mt-3 fa-3x"></i>
                      <br />
                    </MDBCardHeader>
                    <MDBCardBody className="my-2">
                      <div className="h4-responsive mb-2 beige-text">
                        Step Two
                      </div>
                      <div className="text-justify">
                        {" "}
                        We build your kitchen design to the highest standards.
                        When your kitchen design is finalised and your order has
                        been confirmed, our factory will take your kitchen
                        design and bring it to life using only the best, top
                        quality hardware and materials.
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol md="4">
                  <MDBCard
                    className="grey-text mb-4"
                    style={{ minHeight: "485px" }}
                  >
                    <MDBCardHeader className="grey-bg">
                      <div className="h3-responsive mt-4 text-uppercase">
                        Install
                      </div>
                      <i className="fas fa-wrench beige-text mb-4 mt-3 fa-3x"></i>
                      <br />
                    </MDBCardHeader>
                    <MDBCardBody className="my-2">
                      <div className="h4-responsive mb-2 beige-text">
                        Step Three
                      </div>
                      <div className="text-justify">
                        We make the installation process simple, first
                        assembling the kitchen at our warehouse ready for
                        delivery and providing a speedy installation of your
                        kitchen on site. All installations are completed by our
                        highly qualified kitchen installers.
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              <div
                className="text-btn text-right"
                onClick={() => this.props.history.push("/kitchen-services")}
              >
                Learn more about our services{" "}
                <i className="fas fa-angle-right pt-2 ml-2"></i>
              </div>
            </MDBContainer>
          </MDBRow>
        </MDBContainer>
        <div className="banner-kitchen page-header header-filter clear-filter dark-filter white-text mt-5 font-rubikRegular">
          <MDBContainer>
            <MDBRow className="pt-5 pb-3">
              <MDBContainer className="mt-5 mb-5 text-left">
                <MDBCol md="8">
                  <h6 className="h3-responsive mb-3 font-rubikRegular">
                    We tailor a solution for your needs, meeting deadlines,
                    providing exceptional service while staying{" "}
                    <b className="beige-text">competitively priced</b> in the
                    market.
                  </h6>
                  <h6 className="h6-responsive mb-3 font-rubikRegular">
                    Book an appointment for your free meet, greet and quote.
                  </h6>
                  <MDBBtn
                    color="secondary"
                    outline
                    onClick={() => this.props.history.push("/contact-us")}
                  >
                    Book a Consultation
                  </MDBBtn>
                </MDBCol>
              </MDBContainer>
            </MDBRow>
          </MDBContainer>
        </div>

        <MDBRow>
          <MDBCol md="6" className="p-0 m-0">
            <div class="view hover zoom">
              <img
                loading="lazy"
                src={require("../../assets/img/kitchens.png")}
                className="img-fluid w-100 grid-image"
                alt="Kitchens"
              />
              <div className="flex-center rgba-white-strong mask">
                <div className="mx-auto text-center">
                  <PortfolioModal
                    title={"Kitchens"}
                    subtitle={"kitchen"}
                    description={
                      "Discover the beautiful range of Kitchens we can design and install, meeting any budget and requirements. Our elegant kitchens combine high quality hardware and beautiful kitchen cabinetry with exceptional service and installation."
                    }
                    images={KitchenArray}
                    imgCount={"48"}
                  />
                </div>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="6" className="p-0 m-0">
            <div class="view hover zoom">
              <img
                loading="lazy"
                src={require("../../assets/img/laundries.png")}
                className="img-fluid w-100 grid-image"
                alt="laundry"
              />
              <div className="flex-center rgba-white-strong mask">
                <div className="mx-auto text-center">
                  {/*Task 3.2 - Start of Laundry modal should create different pages for the images provided here refer to the Porfolio Modal component*/}
                  <PortfolioModal
                    title={"Laundries"}
                    subtitle={"laundies"}
                    description={
                      "We can design and install custom laundries to fit your space, style and budget."
                    }
                    images={LaundryArray}
                    imgCount={"6"}
                  />
                </div>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6" className="p-0 m-0">
            <div class="view hover zoom">
              <img
                loading="lazy"
                src={require("../../assets/img/storage.png")}
                className="img-fluid w-100 grid-image"
                alt="storage"
              />
              <div className="flex-center rgba-white-strong mask">
                <div className="mx-auto text-center">
                  <PortfolioModal
                    title={"Storage"}
                    subtitle={"storage"}
                    description={
                      "Make the most of any space with our unique range of high quality storage drawers, cupboards and accessories. We can design and source a unique range of storage cabinets, drawers and cupboards to make the most out of your spaces. All our accessories are top quality."
                    }
                    images={StorageArray}
                    imgCount={"21"}
                  />
                </div>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="6" className="p-0 m-0">
            <div class="view hover zoom">
              <img
                loading="lazy"
                src={require("../../assets/img/vanities.JPG")}
                className="img-fluid w-100 grid-image"
                alt="storage"
              />
              <div className="flex-center rgba-white-strong mask">
                <div className="mx-auto text-center">
                  <PortfolioModal
                    title={"Vanities"}
                    subtitle={"vanities"}
                    description={
                      "Make the most of any space with our unique range of high quality storage drawers, cupboards and accessories. We can design and source a unique range of storage cabinets, drawers and cupboards to make the most out of your spaces. All our accessories are top quality."
                    }
                    // images={ProjectArray}
                    // imgCount={'21'}
                  />
                </div>
              </div>
            </div>
          </MDBCol>
        </MDBRow>

        <div
          className="grey-bg pt-5 white-text"
          style={{ borderTop: "2px solid white" }}
        >
          <MDBContainer>
            <MDBRow>
              <MDBContainer className="mt-5 mb-5 text-left">
                <MDBCol md="12">
                  <h2 className="h3-responsive text-left mb-5">
                    {" "}
                    <MDBIcon icon="quote-left" className="mr-2" /> Whether it’s
                    the kitchen of your dreams for your forever home or a
                    standard layout for an investment property, we work with you
                    to create a custom and functional design.
                  </h2>
                </MDBCol>
              </MDBContainer>
            </MDBRow>
          </MDBContainer>
        </div>

        <div style={{ borderBottom: "2px solid #c6ad95" }} className="pb-4">
          <MDBContainer className="mb-5 text-left pt-5 pb-1">
            <MDBRow className="pt-5 pb-5">
              <MDBCol md="5">
                <MDBContainer>
                  <h2 className="display-h3 text-uppercase mt-4 mb-4">
                    We share a passion to{" "}
                    <b className="beige-text">create something</b> extraordinary
                  </h2>
                </MDBContainer>
              </MDBCol>
              <MDBCol md="7" className="vertical-center">
                <MDBContainer>
                  <h6 className="h6-responsive font-rubikRegular">
                    We demand the very best from ourselves so that we can
                    deliver an exceptional customer experience every time to our
                    clients throughout New Zealand. We listen attentively to the
                    ideas from our customers and this has made our kitchens just
                    as unique and special as their owners.
                  </h6>
                  <h6 className="h6-responsive">
                    Current Kitchens is not just any kitchen company but a
                    perfect one stop shop catering to a full range of products
                    and services. We consider our clients varied budgets and
                    offer competitive pricing. Our proactive project management
                    goes the extra mile to deliver your dream kitchen,
                    stress-free.
                  </h6>
                  <div
                    className="text-btn text-left mt-4"
                    onClick={() => this.props.history.push("/kitchen-company")}
                  >
                    Learn more about us{" "}
                    <i className="fas fa-angle-right pt-2 ml-2"></i>
                  </div>
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
