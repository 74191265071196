import React from 'react'
import { withRouter } from 'react-router-dom'
import { ProjectArray } from '../PortfolioModal/ProjectArray'
import { MDBCol, MDBAnimation, MDBContainer } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import ViewMoreComponent from '../Portfolio/ViewMoreComponent'

class KitchenPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isViewMoreEnabled: false,
      currentArrayId: [],
      showMore: false
    }
  }

  handleClick() {
    this.setState({ showMore: true })
  }
  trackCurrentArray(projectArray, type) {
    let projects = projectArray.filter((project) => project.type === type)
    this.setState({ currentArray: projects })
    console.log(this.state.currentArray)
  }
  checkProjectNumber(projectArray, type) {
    let projects = projectArray.filter((project) => project.type === type)

    let numberOfProjects = projects.length
    return numberOfProjects
  }
  getArrayId(projectArray, type) {
    let projects = projectArray.filter((project) => project.type === type)
  }
  checkLength(type) {
    let numberOfProject = this.checkProjectNumber(ProjectArray, type)
    if (numberOfProject >= 9) {
      this.setState({ isViewMoreEnabled: true })
    } else this.setState({ isViewMoreEnabled: false })
  }
  componentDidMount() {
    this.checkLength('kitchen')
    this.trackCurrentArray(ProjectArray, 'kitchen')
  }

  render() {
    const overlay = <div id='sidenav-overlay' style={{ backgroundColor: 'transparent' }} onClick={this.handleTogglerClick} />

    const kitchenProjects = ProjectArray.filter((project) => project.type === 'kitchen')
    const enableViewMore = kitchenProjects.length > 9
    return (
      <>
        <Helmet>
          <title>Our Kitchens | Current Kitchens</title>
          <meta
            name='description'
            content='We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch.'
          />
          <link rel='canonical' href='https://www.currentkitchens.co.nz/our-kitchens' />
        </Helmet>
        <ScrollToTop />

        <div className='kitchen-page-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter'>
          <MDBCol md='6' className='header-text mx-auto text-left header-text-center'>
            <div className='pt-5'>
              <MDBAnimation reveal type='fadeInDown' className='fast'>
                <h1 className='h4-responsive beige-text text-uppercase mt-5 mb-2'>Kitchen Design</h1>
              </MDBAnimation>
              <MDBAnimation reveal type='fadeInUp' className='fast'>
                <h1 className='h1-responsive font-weight-md'>A showcase of our kitchens.</h1>
              </MDBAnimation>
            </div>
          </MDBCol>
        </div>
        <MDBContainer
          style={{
            marginBottom: '10rem'
          }}
        >
          <div
            style={{
              paddingTop: '1rem'
            }}
          >
            <i
              class='fas fa-chevron-left fa-lg mt-5 pointer'
              style={{
                float: 'left'
              }}
              onClick={() => this.props.history.push('/portfolio')}
            ></i>
          </div>
          <div
            style={{
              paddingTop: '1.5rem'
            }}
          >
            <ViewMoreComponent viewMoreEnabled={enableViewMore} projectArray={kitchenProjects} projectType='kitchen' />
          </div>
        </MDBContainer>
      </>
    )
  }
}
export default withRouter(KitchenPage)
