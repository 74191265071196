import { MDBAnimation, MDBCol, MDBContainer } from 'mdbreact'
import React from 'react'
import { withRouter } from 'react-router-dom'
import ScrollToTop from '../Nav/ScrollToTop'
import ViewMoreComponent from '../Portfolio/ViewMoreComponent'
import { ProjectArray } from '../PortfolioModal/ProjectArray'
import { Helmet } from 'react-helmet'

class LaundryPage extends React.Component {
  render() {
    const overlay = <div id='sidenav-overlay' style={{ backgroundColor: 'transparent' }} onClick={this.handleTogglerClick} />
    const laundryProjects = ProjectArray.filter((project) => project.type === 'laundry')
    const enableViewMore = laundryProjects.length > 9
    console.log(enableViewMore)
    return (
      <>
        <Helmet>
          <title>Our Laundries | Current Kitchens</title>
          <meta
            name='description'
            content='We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch.'
          />
          <link rel='canonical' href='https://www.currentkitchens.co.nz/our-laundries' />
        </Helmet>
        <ScrollToTop />

        <div className='laundries-page-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter'>
          <MDBCol md='6' className='header-text mx-auto text-left header-text-center'>
            <div className='pt-5'>
              <MDBAnimation reveal type='fadeInDown' className='fast'>
                <h1 className='h4-responsive beige-text text-uppercase mt-5 mb-2'>Our Laundry projects</h1>
              </MDBAnimation>
              <MDBAnimation reveal type='fadeInUp' className='fast'>
                <h1 className='h1-responsive font-weight-md'>See a selection of our quality laundries.</h1>
              </MDBAnimation>
            </div>
          </MDBCol>
        </div>
        <MDBContainer
          style={{
            marginBottom: '10rem'
          }}
        >
          <div
            style={{
              paddingTop: '1rem'
            }}
          >
            <i
              class='fas fa-chevron-left fa-lg mt-5 pointer'
              style={{
                float: 'left'
              }}
              onClick={() => this.props.history.push('/portfolio')}
            ></i>
          </div>
          <div
            style={{
              paddingTop: '1.5rem'
            }}
          >
            <ViewMoreComponent viewMoreEnabled={enableViewMore} projectArray={laundryProjects} projectType='laundry' diplayNumber={9} />
          </div>
        </MDBContainer>
      </>
    )
  }
}
export default withRouter(LaundryPage)
