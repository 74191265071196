import React, { useEffect } from 'react';
import Nav from './Components/Nav/Nav';
import './App.css';
import Home from './Components/Home/HomePage';
import About from './Components/About/AboutPage';
import Services from './Components/Services/ServicesPage';
import Contact from './Components/Contact/ContactPage';
import Footer from './Components/Footer/Footer';
import PorfolioPage from './Components/Portfolio/PortfolioPage';
import KitchenPage from './Components/Kitchen/KitchenPage';
import LaundryPage from './Components/Laundry/LaundryPage';
import VanityPage from './Components/Vanity/VanityPage';
import StoragePage from './Components/Storage/StoragePage';
import ProjectListings from './Components/Portfolio/ProjectListings'
import SelectedPortfolioiItem from './Components/Portfolio/SelectedPortfolioItem';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import KitchenItem from './Components/Kitchen/KitchenItem';
import LaundryItem from './Components/Laundry/LaundryItem';
import StorageItem from './Components/Storage/StorageItem';
import VanityItem from './Components/Vanity/VanityItem';

export function scrollTo(id) {
  var testDiv = document.getElementById(id);
  window.scrollTo({
    top: (testDiv.offsetTop - 50),
    behavior: 'smooth',
  });
}

function App() {

  return (
    <div className="App font-rubikRegular">
      <Router>
        <Nav />
        <Switch>
          <Route path="/contact-us">
            <Contact />
          </Route>
          <Route path="/kitchen-services">
            <Services />
          </Route>
          <Route path="/kitchen-company">
            <About />
          </Route>
          <Route path="/our-laundries">
           <LaundryPage/>
          </Route>
          <Route path="/our-vanities">
           <VanityPage/>
          </Route>
          <Route path="/our-storage">
           <StoragePage/>
          </Route>
          <Route path="/storage-item">
           <StorageItem/>
          </Route>
          <Route path="/laundry-item">
           <LaundryItem/>
          </Route>
          <Route path="/kitchen-item">
           <KitchenItem/>
          </Route>
          <Route path="/vanities-item">
           <VanityItem/>
          </Route>
          <Route path="/our-kitchens">
           <KitchenPage/>
          </Route>
          <Route path="/portfolio">
            <PorfolioPage/>
          </Route>
          <Route path="/selected-portfolio-item">
            <SelectedPortfolioiItem />
          </Route>
          <Route path="/project-listings">
            <ProjectListings />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
