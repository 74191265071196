import { MDBAnimation, MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import ScrollToTop from "../Nav/ScrollToTop";
import "./style.css";

class AboutUsPage extends React.Component {
  render() {
    return (
      <div className="mx-auto">
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us | Current Kitchens</title>
          <meta
            name="description"
            content="We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch."
          />
          <link
            rel="canonical"
            href="https://www.currentkitchens.co.nz/kitchen-company"
          />
        </Helmet>
        <ScrollToTop />
        <div className="about-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter">
          <MDBCol
            md="6"
            className="header-text mx-auto text-left header-text-center"
          >
            <div className="pt-5">
              <MDBAnimation reveal type="fadeInDown" className="fast">
                <h1 className="h4-responsive beige-text text-uppercase mt-5 mb-2">
                  About us
                </h1>
              </MDBAnimation>
              <MDBAnimation reveal type="fadeInUp" className="fast">
                <h1 className="h1-responsive font-weight-md">
                  A kitchen company
                </h1>
              </MDBAnimation>
            </div>
          </MDBCol>
        </div>
        <MDBContainer className="mt-5 divider-margin">
          <div className="pb-4">
            <MDBContainer className="mb-5 text-left pt-5">
              <MDBRow className="pt-5 pb-5">
                <MDBCol md="5">
                  <MDBContainer>
                    <h2 className="display-h3 text-uppercase mt-4 mb-4">
                      Transforming homes one
                      <b className="beige-text"> dream kitchen</b> at a time
                    </h2>
                  </MDBContainer>
                </MDBCol>
                <MDBCol md="7" className="vertical-center">
                  <MDBContainer>
                    <h6 className="h6-responsive font-rubikRegular">
                      We demand the very best from ourselves so that we can
                      deliver an exceptional customer experience every time to
                      our clients throughout New Zealand. We listen attentively
                      to the ideas from our customers and this has made our
                      kitchens just as unique and special as their owners.
                    </h6>
                    <h6 className="h6-responsive">
                      Current Kitchens is not just any kitchen company but a
                      perfect one stop shop catering to a full range of products
                      and services. We consider our clients varied budgets and
                      offer competitive pricing. Our proactive project
                      management goes the extra mile to deliver your dream
                      kitchen, stress-free.
                    </h6>
                  </MDBContainer>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </MDBContainer>
        <MDBContainer>
          <MDBRow className="pb-5 mx-auto">
            <MDBCol md="12" className="text-left pt-6 pb-5 mx-auto" id="team">
              <div className="text-center">
                <MDBAnimation reveal type="fadeInDown" className="fast">
                  <h2 className="h1-responsive mb-4 mt-3 text-uppercase beige-text">
                    Our amazing people
                  </h2>
                </MDBAnimation>
                <hr
                  className="grey accent-2 mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px" }}
                />
              </div>
            </MDBCol>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="6" className="mx-auto">
                  <img
                    src={require("../../assets/img/team/Aroha-bw.png")}
                    alt="Aroha"
                    style={{ borderRadius: "5px", height: "300px" }}
                  />
                  <h3 className="mt-4 mb-2 beige-text">Aroha</h3>
                  <h5 className="mb-5">Managing Director</h5>
                </MDBCol>
                <MDBCol md="6" className="mx-auto">
                  <img
                    src={require("../../assets/img/team/Cadence-bw.png")}
                    alt="Aroha"
                    style={{ borderRadius: "5px", height: "300px" }}
                  />
                  <h3 className="mt-4 mb-2 beige-text">Cadence (Cat)</h3>
                  <h5 className="mb-5">Logistics and Operations</h5>
                </MDBCol>
                <MDBCol md="6" className="mx-auto">
                  <img
                    src={require("../../assets/img/team/Dene-bw.png")}
                    alt="Aroha"
                    style={{ borderRadius: "5px", height: "300px" }}
                  />
                  <h3 className="mt-4 mb-2 beige-text">Dene</h3>
                  <h5 className="mb-5">Accounts and Customer Service</h5>
                </MDBCol>
                <MDBCol md="6" className="mx-auto">
                  <img
                    src={require("../../assets/img/team/andrea.jpg")}
                    alt="Andrea"
                    style={{ borderRadius: "5px", height: "300px" }}
                  />
                  <h3 className="mt-4 mb-2 beige-text">Andrea</h3>
                  <h5 className="mb-5">Sales and Administration</h5>
                </MDBCol>
                {/* <MDBCol md='4' className='mx-auto'>
                  <img src={require('../../assets/img/team/Matt-bw.png')} alt='Aroha' style={{ borderRadius: '5px', height: '300px' }} />
                  <h3 className='mt-4 mb-2 beige-text'>Matt</h3>
                  <h5 className='mb-5'>Installer</h5>
                </MDBCol> */}
              </MDBRow>
            </MDBContainer>
          </MDBRow>
        </MDBContainer>
        <div className="banner-about page-header header-filter clear-filter dark-filter white-text mt-5">
          <MDBContainer>
            <MDBRow className="pt-5 pb-3">
              <MDBContainer className="mt-5 mb-5 text-left font-rubikRegular">
                <MDBCol md="8">
                  <h6 className="h3-responsive mb-3 font-rubikRegular">
                    Your kitchen is the <b className="beige-text">heart</b> of a
                    beautiful home.
                  </h6>
                  <h6 className="h6-responsive mb-3 font-rubikRegular">
                    Book an appointment for your free meet, greet and quote.
                  </h6>
                  <MDBBtn
                    color="secondary"
                    outline
                    onClick={() => this.props.history.push("/contact-us")}
                  >
                    Book a Consultation
                  </MDBBtn>
                </MDBCol>
              </MDBContainer>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    );
  }
}

export default withRouter(AboutUsPage);
