import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom';
import { scrollTo } from '../../App'
import './style.css'

class AboutUsPage extends React.Component {

    render() {
        return (
            <div className="mx-auto">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kitchen Services | Current Kitchens</title>
                    <meta name="description"
                        content="We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch." />
                    <link rel="canonical" href="https://www.currentkitchens.co.nz/kitchen-services" />
                </Helmet>
                <ScrollToTop />
                <div className="services-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter">
                    <MDBCol md="6" className="header-text mx-auto text-left header-text-center">
                        <div className="pt-5">
                            <MDBAnimation reveal type="fadeInDown" className="fast">
                                <h1 className="h4-responsive beige-text text-uppercase mt-5 mb-2">Our services</h1>
                            </MDBAnimation>
                            <MDBAnimation reveal type="fadeInUp" className="fast">
                                <h1 className="h1-responsive font-weight-md">Bespoke kitchen specialists</h1>
                            </MDBAnimation>
                        </div>
                    </MDBCol>
                </div>
                <MDBContainer className="mt-5 divider-margin">
                    <div className="pb-4">
                        <MDBContainer className="mb-5 text-left pt-5">
                            <MDBRow className="pt-5 pb-5">
                                <MDBCol md="5">
                                    <MDBContainer>
                                        <h2 className="display-h3 text-uppercase mt-4 mb-4">The way to your<b className="beige-text"> dream kitchen </b>with current kitchens</h2>
                                    </MDBContainer>
                                </MDBCol>
                                <MDBCol md="7" className="vertical-center">
                                    <MDBContainer>
                                        <h1 className="pt-3 pb-2 beige-text">What we do</h1>
                                        <h6 className="h6-responsive font-rubikRegular">
                                            We understand the importance involved in creating a functional space that suits your lifestyle. We are here to help in balancing the look, style and layout to ensure your kitchen is functional, easy to use, easy to clean and be the focal point of your home.
                                    </h6>
                                        <h6 className="h6-responsive font-rubikRegular">
                                            We offer full end to end kitchen, laundry and storage services from the design, to the build and installation - making the process simple and stress free.
                                    </h6>
                                        <div className="text-btn text-left" onClick={() => scrollTo('sectors')}>Learn about the sectors we supply to <i className="fas fa-angle-right pt-2 ml-2"></i></div>
                                    </MDBContainer>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </MDBContainer>
                <MDBContainer>
                    <MDBRow className="mb-5 rubikRegular">
                        <MDBCol md="4">
                            <MDBCard className="grey-text mb-4">
                                <MDBCardHeader className="grey-bg">
                                    <div className="h3-responsive mt-4 text-uppercase">Design</div>
                                    <i className="fas fa-paint-brush beige-text mb-4 mt-3 fa-3x"></i><br />
                                </MDBCardHeader>
                                <MDBCardBody className="my-2">
                                    <div className="h4-responsive mb-2 beige-text">Step One</div>
                                    <div className="text-justify"> We discover the look you would like to achieve for your kitchen, recommending colours, door profiles, handles and bench top materials complimentary to your personal style and taste. You can virtually walk-through your kitchen design before finalizing.
                                        </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="grey-text mb-4">
                                <MDBCardHeader className="grey-bg">
                                    <div className="h3-responsive mt-4 text-uppercase">Build</div>
                                    <i className="fas fa-ruler beige-text mb-4 mt-3 fa-3x"></i><br />
                                </MDBCardHeader>
                                <MDBCardBody className="my-2">
                                    <div className="h4-responsive mb-2 beige-text">Step Two</div>
                                    <div className="text-justify">We build your kitchen design to the highest standards. When your kitchen design is finalised and your order has been confirmed, our factory will take your kitchen design and bring it to life using only the best, top quality hardware and materials.
                                            </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="grey-text mb-4">
                                <MDBCardHeader className="grey-bg">
                                    <div className="h3-responsive mt-4 text-uppercase">Install</div>
                                    <i className="fas fa-wrench beige-text mb-4 mt-3 fa-3x"></i><br />
                                </MDBCardHeader>
                                <MDBCardBody className="my-2">
                                    <div className="h4-responsive mb-2 beige-text">Step Three</div>
                                    <div className="text-justify">We make the installation process simple and stress free, first by assembling the kitchen at our warehouse ready for delivery and providing a speedy installation of your kitchen on site. All installations are completed by our highly qualified kitchen installers.
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <div>
                    <MDBContainer className="pb-5">
                        <MDBRow className="pt-4 pb-5 mx-auto">
                            <MDBCol md="12" className="text-left pt-6 pb-5 mx-auto" id="sectors">
                                <div className="text-center">
                                    <MDBAnimation reveal type="fadeInDown" className="fast">
                                        <h2 className="h1-responsive mb-4 text-uppercase beige-text">Sectors we supply</h2>
                                    </MDBAnimation>
                                    <hr className="grey accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
                                    <div className="h4-responsive font-weight-md pt-3">We supply to a range of different sectors including both residential and commercial projects.</div>
                                </div>
                            </MDBCol>
                            <MDBContainer>
                                <MDBRow className="rubikRegular mx-auto">
                                    <MDBCol md="10" className="mx-auto">
                                        <MDBCard className="grey-text mb-4 card-sectors">
                                            <MDBCardHeader className="white-bg card-header-sectors">
                                                <i className="fas fa-industry beige-text mb-2 mt-3 fa-3x"></i><br />
                                                <div className="h3-responsive mt-3 grey-ck-text">Commercial Projects</div>
                                            </MDBCardHeader>
                                            <MDBCardBody className="my-2 text-justify">
                                                The best commercial kitchens will be available for your use within the fastest possible time and bearing the best possible features. Our kitchens are custom designed for each unique project, making the most of space, matching the style and flair of the building design, and meeting all budgets and timelines. Examples of our commercial projects include resthomes, motels and hotels.
                                        </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mb-5 rubikRegular mx-auto">
                                    <MDBCol md="6">
                                        <MDBCard className="grey-text mb-4 card-sectors">
                                            <MDBCardHeader className="white-bg card-header-sectors">
                                                <i className="fas fa-home beige-text mb-2 mt-3 fa-3x"></i><br />
                                                <div className="h3-responsive mt-3 grey-ck-text">New home builds</div>
                                            </MDBCardHeader>
                                            <MDBCardBody className="my-2 text-justify">
                                                Our kitchens combine progressive and innovative design, and are built to the highest standards using quality materials, all delivered with exceptional customer service.                                        </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <MDBCard className="grey-text mb-4 card-sectors">
                                            <MDBCardHeader className="white-bg card-header-sectors">
                                                <i className="fas fa-building beige-text mb-2 mt-3 fa-3x"></i><br />
                                                <div className="h3-responsive mt-3 grey-ck-text">Apartments & townhouses</div>
                                            </MDBCardHeader>
                                            <MDBCardBody className="my-2 text-justify">
                                                Visually stunning kitchen fit-outs for your development that’s functional. Our kitchens are created with an abundance of storage and with minimal wasted space.                                        </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <MDBCard className="grey-text mb-4 card-sectors">
                                            <MDBCardHeader className="white-bg card-header-sectors">
                                                <i className="fas fa-hotel beige-text mb-2 mt-3 fa-3x"></i><br />
                                                <div className="h3-responsive mt-3 grey-ck-text">Investment properties</div>
                                            </MDBCardHeader>
                                            <MDBCardBody className="my-2 text-justify">
                                                Partnering with you to produce tailored kitchen designs within your budget, adding considerable value to the finished package that’s attractive for your tenants but also attractive on price.                                        </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <MDBCard className="grey-text mb-4 card-sectors">
                                            <MDBCardHeader className="white-bg card-header-sectors">
                                                <i className="fas fa-landmark beige-text mb-2 mt-3 fa-3x"></i><br />
                                                <div className="h3-responsive mt-3 grey-ck-text">Dream homes & renovations</div>
                                            </MDBCardHeader>
                                            <MDBCardBody className="my-2 text-justify">
                                                Our fantastic team will help bring your dream kitchen into reality. Your kitchen cabinets will not just be storage facilities, but versatile, flexible and appealing focal points.                                        </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                                <div className="text-btn text-right" onClick={() => this.props.history.push('/contact-us')}>Enquire about your project or idea <i className="fas fa-angle-right pt-2 ml-2"></i></div>
                            </MDBContainer>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </div >
        );
    }
}

export default withRouter(AboutUsPage);