import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView } from 'mdbreact'
import React from 'react'
import { withRouter } from 'react-router-dom'
class CarouselComponent extends React.Component {
  render() {
    const { selectedTypeProject } = this.props

    const projectItems = selectedTypeProject.imgUrl.map((img, i) => {
      return (
        <MDBCarouselItem key={img} itemId={i + 1}>
          <MDBView style={{ maxHeight: '1000px' }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                loading='lazy'
                className=' mx-auto imgHeight'
                src={require('../../assets/img/projects/' + img)}
                alt={selectedTypeProject.name}
              />
            </div>
          </MDBView>
        </MDBCarouselItem>
      )
    })
    return (
      <>
        <MDBCarousel
          activeItem={1}
          length={projectItems.length}
          showControls={true}
          showIndicators={true}
          onHoverStop={true}
          mobileGesture={true}
          interval={500000}
        >
          <MDBCarouselInner>{projectItems}</MDBCarouselInner>
        </MDBCarousel>
      </>
    )
  }
}

export default withRouter(CarouselComponent)
