import React from 'react'
import { withRouter } from 'react-router-dom'
import { ProjectArray } from '../PortfolioModal/ProjectArray'
// import { KitchenArray } from '../PortfolioModal/kitchensArray';
import { MDBCol, MDBAnimation, MDBBtn, MDBRow, MDBContainer } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import CarouselComponent from '../Portfolio/CarouselComponent'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'

class KitchenMultiItem extends React.Component {
  render() {
    const selectedKitchenProject = this.props.project
    const kitchenProjectName = (
      <>
        <h1>{selectedKitchenProject.name}</h1>
      </>
    )
    // const kitchenProjectDescription = (
    //     <>
    //         <p>{selectedKitchenProject.description}</p>
    //     </>
    // )
    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{selectedKitchenProject.name + ' | Current Kitchens'}</title>
          <meta
            name='description'
            content='We specialise in the design and installation of bespoke commercial and residential kitchens in Christchurch.'
          />
          <link rel='canonical' href={'https://www.currentkitchens.co.nz/kitchen-item' + this.props.location.search} />
        </Helmet>
        <ScrollToTop />
        <div className='kitchen-page-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter'>
          <MDBCol md='6' className='header-text mx-auto text-left header-text-center'>
            <div className='pt-5'>
              <MDBAnimation reveal type='fadeInDown' className='fast'>
                <h1 className='h4-responsive beige-text text-uppercase mt-5 mb-2'>Our Portfolio</h1>
              </MDBAnimation>
              <MDBAnimation reveal type='fadeInUp' className='fast'>
                <h1 className='h1-responsive font-weight-md'>{kitchenProjectName}</h1>
              </MDBAnimation>
            </div>
          </MDBCol>
        </div>
        <MDBContainer>
          <MDBRow className='pt-5'>
            <MDBCol md='1'>
              <i class='fas fa-chevron-left fa-lg  mt-3 mb-4 pointer' onClick={() => this.props.history.push('/our-kitchens')}></i>
            </MDBCol>
          </MDBRow>
          {selectedKitchenProject.multipleProjects.map((project) => (
            <>
              <MDBRow>
                <MDBCol>
                  <div className='h2-responsive pl-4 text-left mt-2'>{project.name}</div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md='12'>
                  <div className='h6-responsive pl-4 mb-5 text-left mt-2'>{ReactHtmlParser(project.description)}</div>
                </MDBCol>
              </MDBRow>

              <div
                style={{
                  paddingBottom: '5rem'
                }}
              >
                <CarouselComponent selectedTypeProject={project} />
              </div>
            </>
          ))}

          <div className='text-left mt-lg-5 mt-5' style={{ paddingBottom: '5rem' }}>
            <MDBBtn color='secondary' onClick={() => this.props.history.push(`/our-kitchens`)}>
              Back
            </MDBBtn>
          </div>
        </MDBContainer>
      </>
    )
  }
}

export default withRouter(KitchenMultiItem)
